
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.product-card::v-deep {
    max-width: 100%;

    &.reduced-width {
        max-width: 45ch;
    }

    .product-title {
        word-break: keep-all;
        font-size: 1.5rem;
    }

    .price-loader {
        width: 80px;
    }
}
