
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.product-action::v-deep {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.reduced-width {
        max-width: 36ch;
    }

    .action-title {
        word-break: keep-all;
    }
}
